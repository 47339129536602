import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useToast } from "@introist/react-foundation/v2";
import { FormError, FormResponse, loadForm, useFormsApi } from "./api";
import { useState } from "react";
import { FormBuilder } from "./components/FormBuilder";

export const FormRoute = () => {
  const toast = useToast();
  const { hash } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState<FormError[]>([]);

  const formsApi = useFormsApi();
  const { mutateAsync } = formsApi.submit({
    onSuccess: (response) => {
      if (response.ok) {
        setSubmitted(true);
      } else if (response.errors) {
        setErrors(response.errors);
      } else {
        toast.error("Submit failed. Please check the fields for errors!");
      }
    },
  });

  const onSubmit = async (formData: FormResponse) => {
    await mutateAsync({ hash: hash!, responses: formData });
  };

  const {
    data: form,
    isError,
    isLoading,
  } = useQuery(["form", hash], () => loadForm(hash!));

  return (
    <FormBuilder
      isLoading={isLoading}
      isError={isError}
      isSubmitted={submitted}
      form={form}
      errors={errors}
      onSubmit={onSubmit}
    />
  );
};
