import { useMemo } from "react";

import {
  Input,
  Field,
  TextArea,
  DateInput,
  Select,
} from "@introist/react-foundation/v2";
import { FormFieldSpec } from "../schema";

type Props = {
  field: FormFieldSpec;
  value: string;
  error?: string;
  onChanged: (newValue: string) => void;
};

export const FormField = ({ field, value, error, onChanged }: Props) => {
  const input = useMemo(() => {
    if (field.options) {
      return (
        <Select
          style={{ width: "100%" }}
          value={value}
          options={field.options.map((opt) => ({ key: opt }))}
          onSelect={(opt) => onChanged(opt.key)}
        />
      );
    }

    if (field.type === "shorttext") {
      return (
        <Input type="text" value={value} onChange={onChanged} error={!!error} />
      );
    }
    if (field.type === "longtext") {
      return (
        <TextArea rows={5} value={value} onChange={onChanged} error={!!error} />
      );
    }
    if (field.type === "number") {
      return (
        <Input
          type="number"
          value={value}
          onChange={onChanged}
          error={!!error}
        />
      );
    }
    if (field.type === "date") {
      return (
        <DateInput
          value={value}
          onChange={(value) => onChanged(value ?? "")}
          error={!!error}
        />
      );
    }

    return (
      <Input type="text" value={value} onChange={onChanged} error={!!error} />
    );
  }, [field.type, field.options, value, error, onChanged]);

  return (
    <Field title={field.title} error={error} required={field.required}>
      {input}
    </Field>
  );
};
