import styled from "@emotion/styled";
import { Button, H3, P, useTheme } from "@introist/react-foundation/v2";
import { useState } from "react";
import { SmoothCard } from "../../../components/SmoothCard";
import { FormError, FormResponse } from "../api";
import { FormFieldSpec, FormTheme } from "../schema";
import { FormField } from "./FormField";

const FormFrame = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxLarge);
  max-width: 540px;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
`;

const FormMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
`;

const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FormIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: var(--spacing-xLarge);
`;

export interface FormProps {
  title: string;
  description?: string;
  fields: FormFieldSpec[];
  formTheme?: FormTheme | null;
  onSubmit: (formData: FormResponse) => Promise<void>;
  errors?: FormError[];
}

export const IntroistForm = ({
  title,
  description,
  fields,
  formTheme,
  onSubmit,
  errors = [],
}: FormProps) => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState<FormResponse>({});
  return (
    <SmoothCard style={{ marginTop: "150px" }}>
      <FormFrame>
        <FormMeta>
          {formTheme?.iconUrl && <FormIcon src={formTheme?.iconUrl} />}

          <H3>{title}</H3>
          <P
            style={{ color: theme.palette.foreground.subdued }}
            dangerouslySetInnerHTML={{ __html: description || "" }}
          />
        </FormMeta>

        <Fields>
          {fields.map((field) => (
            <FormField
              field={field}
              value={formData[field.key] ?? ""}
              onChanged={(newValue) =>
                setFormData({ ...formData, [field.key]: newValue })
              }
              error={errors.find((err) => err.key === field.key)?.error}
            />
          ))}
          <FormActions>
            <Button onClickWithLoading={() => onSubmit(formData)}>
              Submit
            </Button>
          </FormActions>
        </Fields>
      </FormFrame>
    </SmoothCard>
  );
};
