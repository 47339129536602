import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FormBuilder } from "./components/FormBuilder";
import { Form, FormSchema } from "./schema";

export const FormPreviewRoute = () => {
  const { hash } = useParams();
  const [form, setForm] = useState<Form | undefined>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (hash) {
      try {
        setSubmitted(false);
        setLoading(true);
        const formDataAsString = atob(hash);
        const formDataObject = JSON.parse(formDataAsString);
        const parsedForm = FormSchema.transform((val) => {
          return {
            ...val,
            description: "Form preview, submissions will not be saved.",
          };
        }).safeParse(formDataObject);

        if (parsedForm.success) {
          setForm(parsedForm.data);
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [hash]);

  const onSubmit = async () =>
    new Promise<void>((res) => {
      setTimeout(() => {
        setSubmitted(true);
        res();
      }, 1500);
    });
  return (
    <FormBuilder
      onSubmit={onSubmit}
      form={form}
      isLoading={isLoading}
      isError={isError}
      isSubmitted={submitted}
    />
  );
};
