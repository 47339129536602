import styled from "@emotion/styled";
import {
  CircularLoader,
  H4,
  Icon,
  IconName,
  P,
  useTheme,
} from "@introist/react-foundation/v2";
import { SmoothCard } from "./SmoothCard";

interface IconWrapperProps {
  state: "success" | "error";
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-medium);
  width: fit-content;
  border-radius: var(--rounding-large);
  background-color: ${({ state }) =>
    state === "success"
      ? "var(--palette-primary-ghosted)"
      : "var(--palette-danger-ghosted)"};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  align-items: center;
  justify-content: center;
`;

export interface InformationCardProps {
  title: string;
  iconName: IconName;
  description: string;
  state?: "success" | "error";
  loader?: boolean;
}

export const InformationCard = ({
  title,
  iconName,
  description,
  state = "success",
  loader,
}: InformationCardProps) => {
  const { theme } = useTheme();
  return (
    <SmoothCard>
      {loader ? (
        <CircularLoader />
      ) : (
        <IconWrapper state={state}>
          <Icon
            name={iconName}
            color={
              state === "success"
                ? theme.palette.primary.default
                : theme.palette.danger.default
            }
          />
        </IconWrapper>
      )}

      <TextWrapper>
        <H4>{title}</H4>
        <P style={{ color: theme.palette.foreground.subdued }}>{description}</P>
      </TextWrapper>
    </SmoothCard>
  );
};
