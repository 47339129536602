import { UseQueryOptions, useMutation } from "@tanstack/react-query";

export const generateMutation =
  <Mutation, Response>(
    apiFunc: (object: Mutation) => Promise<Response>,
    defaultOptions?: UseQueryOptions<Response>
  ) =>
  (options?: UseQueryOptions<Response>) => {
    return useMutation<Response, Error, Mutation>(
      (object: Mutation) => apiFunc(object),
      {
        ...defaultOptions,
        ...options,
      } as any
    );
  };
