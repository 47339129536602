import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Task, TaskSchema } from "./api";
import { TaskBuilder } from "./components/TaskBuilder";

export const TaskPreviewRoute = () => {
  const { hash } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [task, setTask] = useState<Task>();

  useEffect(() => {
    if (hash) {
      try {
        setLoading(true);
        const taskDataAsString = atob(hash);
        const taskDataObject = JSON.parse(taskDataAsString);
        const formData = TaskSchema.parse(taskDataObject);
        setTimeout(() => {
          setTask(formData);
          setLoading(false);
        }, 1500);
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    }
  }, [hash]);

  return <TaskBuilder isError={isError} isLoading={isLoading} task={task} />;
};
