import { GlobalView } from "../../../components";

import { InformationCard } from "../../../components/InformationCard";
import { Task } from "../api";

export interface TaskBuilderProps {
  isLoading?: boolean;
  isError?: boolean;
  task?: Task;
}

export const TaskBuilder = ({ isLoading, isError, task }: TaskBuilderProps) => {
  if (!isLoading && isError) {
    return (
      <GlobalView>
        <InformationCard
          iconName="crossCircle"
          title="Failed to complete the task"
          description="Seems that the task is not active anymore"
          state="error"
        />
      </GlobalView>
    );
  }

  if (!isLoading && task) {
    return (
      <GlobalView>
        <InformationCard
          iconName="checkCircle"
          title={task.description}
          description="Your task is now completed!"
        />
      </GlobalView>
    );
  }

  return (
    <GlobalView>
      <InformationCard
        iconName="progress"
        title="Completing the task"
        description="Wait until the task is completed"
        loader
      />
    </GlobalView>
  );
};
