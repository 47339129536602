import { z } from "zod";

export const FormThemeSpec = z.object({
  imageOverlay: z.string().optional(),
  imageUrl: z.string().optional(),
  iconUrl: z.string().optional(),
});

export type FormTheme = z.infer<typeof FormThemeSpec>;

export const FormFieldSpecSchema = z.object({
  key: z.string(),
  title: z.string(),
  description: z.string().optional(),
  required: z.boolean().optional(),
  type: z.enum(["shorttext", "longtext", "date", "number", "email"]),
  options: z.string().array().nullish(),
});

export type FormFieldSpec = z.infer<typeof FormFieldSpecSchema>;

export const FormSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  fields: z.array(FormFieldSpecSchema),
  theme: FormThemeSpec.nullish(),
});

export type Form = z.infer<typeof FormSchema>;
