import axios from "axios";
import { z } from "zod";
import { AppConfig } from "../../Config";
import { generateMutation } from "../../utils/apiUtils";

export const TaskSchema = z.object({
  description: z.string(),
  confirmText: z.string(),
});

export type Task = z.infer<typeof TaskSchema>;

const completeTask = (hash: string): Promise<Task> => {
  return axios
    .post<Task>(`${AppConfig.introistApiUrl}/tasks/${hash}`)
    .then((res) => res.data)
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        return Promise.reject(error.response.data);
      }
      throw error;
    });
};

export const useTasksApi = () => ({
  complete: generateMutation<string, Task>(completeTask),
});
