import { FormRoute } from "./modules/forms/FormRoute";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NotFound } from "./NotFound";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, ToastProvider } from "@introist/react-foundation/v2";
import { TaskRoute } from "./modules/tasks/TaskRoute";
import { FormPreviewRoute } from "./modules/forms/FormPreviewRoute";
import { TaskPreviewRoute } from "./modules/tasks/TaskPreviewRoute";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function App() {
  const router = createBrowserRouter([
    {
      path: "/forms/preview/:hash",
      element: <FormPreviewRoute />,
    },
    {
      path: "/forms/:hash",
      element: <FormRoute />,
    },
    {
      path: "/tasks/preview/:hash",
      element: <TaskPreviewRoute />,
    },
    {
      path: "/tasks/:hash",
      element: <TaskRoute />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <ThemeProvider>
      <ToastProvider defaultPlacement="bottom-center">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
