import axios from "axios";
import { AppConfig } from "../../Config";
import { generateMutation } from "../../utils/apiUtils";
import { Form } from "./schema";

export interface FormResponse {
  [index: string]: string;
}

export interface FormError {
  key: string;
  error: string;
}

export const loadForm = (hash: string): Promise<Form> => {
  return axios
    .get(`${AppConfig.introistApiUrl}/forms/${hash}`)
    .then((res) => res.data);
};

export interface FormSubmitRequest {
  hash: string;
  responses: FormResponse;
}

export interface FormSubmitResponse {
  ok?: boolean;
  errors?: FormError[];
}

export const submitForm = ({
  hash,
  responses,
}: FormSubmitRequest): Promise<FormSubmitResponse> => {
  return axios
    .post(`${AppConfig.introistApiUrl}/forms/${hash}`, { responses })
    .then(() => ({ ok: true }))
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        return { errors: error.response.data };
      }
      throw error;
    });
};

export const useFormsApi = () => ({
  submit: generateMutation<FormSubmitRequest, FormSubmitResponse>(submitForm),
});
