import styled from "@emotion/styled";

export const SmoothCard = styled.div`
  background-color: var(--palette-background-default);
  padding: 64px;
  border-radius: var(--rounding-medium);
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  align-items: center;
  justify-content: center;
  min-height: 400px;
  min-width: 540px;
  box-sizing: border-box;
`;
