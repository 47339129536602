import { GlobalView } from "../../../components";
import { Helmet } from "react-helmet";
import { FormError, FormResponse } from "../api";
import { IntroistForm } from "./Form";
import { InformationCard } from "../../../components/InformationCard";
import { Form } from "../schema";

interface FormBuilderProps {
  isLoading?: boolean;
  isError?: boolean;
  isSubmitted?: boolean;
  form?: Form;
  onSubmit: (formData: FormResponse) => Promise<void>;
  errors?: FormError[];
}

export const FormBuilder = ({
  isLoading,
  isError,
  isSubmitted,
  form,
  onSubmit,
  errors,
}: FormBuilderProps) => {
  if (isLoading) {
    return (
      <GlobalView>
        <InformationCard
          iconName="checkCircle"
          title="Loading form"
          description="Please wait while we are loading the form"
          loader
        />
      </GlobalView>
    );
  }

  if (form && isSubmitted) {
    return (
      <GlobalView>
        <InformationCard
          iconName="checkCircle"
          title={`Thank you for completing ${form.title}`}
          description=""
        />
      </GlobalView>
    );
  }

  if (isError || !form) {
    return (
      <GlobalView>
        <InformationCard
          state="error"
          iconName="crossCircle"
          title="Failed to load form"
          description="It seems that this form does not exist or it is not available anymore."
        />
      </GlobalView>
    );
  }

  return (
    <GlobalView theme={form.theme} style={{ justifyContent: "flex-start" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{form?.title}</title>
        <meta name="description" content={form.title} />
        <link rel="icon" href={form?.theme?.iconUrl} sizes="16x16" />
      </Helmet>
      <IntroistForm
        title={form.title}
        description={form.description}
        fields={form.fields}
        onSubmit={onSubmit}
        errors={errors}
        formTheme={form.theme}
      />
    </GlobalView>
  );
};
