export interface Config {
  introistApiUrl: string;
}

const EnvConfig: { [index: string]: Config } = {
  dev: {
    introistApiUrl: "http://localhost:3100",
  },
  test: {
    introistApiUrl: "https://api.test.introist.com",
  },
  production: {
    introistApiUrl: "https://api.introist.com",
  },
};

export const AppConfig =
  EnvConfig[process.env.REACT_APP_ENV ?? ""] ?? EnvConfig.production;
