import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Task, useTasksApi } from "./api";
import { TaskBuilder } from "./components/TaskBuilder";

export const TaskRoute = () => {
  const { hash } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [task, setTask] = useState<Task>();

  const taskApi = useTasksApi();
  const { mutate } = taskApi.complete({
    onSuccess: (task) => {
      setTask(task);
      setLoading(false);
      setError(false);
    },
    onError: () => {
      setLoading(false);
      setError(true);
      setTask(undefined);
    },
  });

  useEffect(() => {
    if (hash) {
      setLoading(true);
      setError(false);
      setTimeout(() => mutate(hash), 1500);
    }
  }, [hash, mutate]);

  return <TaskBuilder isError={isError} isLoading={isLoading} task={task} />;
};
