import { GlobalView } from "./components";
import { InformationCard } from "./components/InformationCard";

export const NotFound = () => {
  return (
    <GlobalView>
      <InformationCard
        iconName="crossCircle"
        title="Something failed"
        description="This is most likely not what you are looking for"
        state="error"
      />
    </GlobalView>
  );
};
