import styled from "@emotion/styled";
import { ComponentProps } from "react";

import { ReactComponent as Logo } from "./dimmed-introist-logo.svg";

export const ViewFrame = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0 var(--spacing-xxLarge);
  background-color: var(--palette-surface-dimmed);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const PoweredBy = styled.div`
  margin-top: var(--spacing-xLarge);
  color: var(--palette-foreground-dimmed);
  font-size: 13px;
  font-family: var(--typography-font-text);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-medium);
`;

const BackgroundImage = styled.div<Theme>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vh;
  background-image: url(${(props) => props.imageUrl || ""});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
`;

const Overlay = styled.div<Theme>`
  background-color: ${(props) => props.imageOverlay || ""};
  width: 100%;
  height: 100%;
  opacity: 0.7;
`;

interface Theme {
  imageUrl?: string;
  imageOverlay?: string;
}

export interface GlobalViewProps extends ComponentProps<"div"> {
  theme?: Theme | null;
}

export const GlobalView = ({ theme, children, ...rest }: GlobalViewProps) => {
  return (
    <ViewFrame {...rest}>
      <Content style={{ minHeight: !theme ? "100vh" : undefined }}>
        {children}
        <PoweredBy>
          Powered by <Logo />
        </PoweredBy>
      </Content>
      {theme && (
        <BackgroundImage imageUrl={theme.imageUrl}>
          <Overlay imageOverlay={theme.imageOverlay} />
        </BackgroundImage>
      )}
    </ViewFrame>
  );
};
